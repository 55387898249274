import { createBrowserHistory } from 'history';
import { store } from '../redux';

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
});
const pushHistory = (route: string, addData?: any): any => {
  const mystate = store.getState().custom as any;
  const queryParam = mystate.data.queryUrl;
  // Strip faulty second query separator if route contains it
  const newRoute = route.includes('?') ? `${route}&${queryParam.replace('?', '')}` : route + queryParam;
  if (addData) {
    history.push(newRoute, addData);
  } else {
    history.push(newRoute);
  }
};
function qs(queryString: string, parameter: string): string {
  const strToMap = queryString
    .slice(1)
    .split('&')
    .reduce((acc: any, item: string) => {
      const [name, ...value] = item.split('=');
      return {
        ...acc,
        [name]: value.join('='),
      };
    }, {});
  return strToMap[parameter] === undefined ? '' : strToMap[parameter];
}
export { pushHistory, history, qs };
