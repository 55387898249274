// import { pushStorefrontDataToDatalayer } from '../../analytics/storefront'

export const FETCH_STOREFRONT_PENDING = 'storefront//FETCH_STOREFRONT_PENDING';
export const FETCH_STOREFRONT_DONE = 'storefront//FETCH_STOREFRONT_DONE';
export const FETCH_STOREFRONT_ERROR = 'storefront//FETCH_STOREFRONT_ERROR';
export const STORE_STOREFRONTID = 'storefront//STORE_STOREFRONTID';
export const SET_STEP = 'storefront//SET_STEP';

interface Action {
  type: string;
  payload: any;
}

export function pending(): Action {
  return {
    type: FETCH_STOREFRONT_PENDING,
    payload: {},
  };
}

export function done(data: any): Action {
  // pushStorefrontDataToDatalayer(data)
  return {
    type: FETCH_STOREFRONT_DONE,
    payload: {
      data,
    },
  };
}

export function error(err: any): Action {
  return {
    type: FETCH_STOREFRONT_ERROR,
    payload: {
      err,
    },
  };
}

export function getStep(store: any) {
  return store.storefrontData.data?.custom?.formSection;
}

export function getOfferExpiration(store: any) {
  return store.storefrontData.data?.custom?.offer.validityEndDate;
}

export function getLinkExpiration(store: any) {
  return store.storefrontData.data?.custom?.expirationDate;
}

export function storeId(id: any): Action {
  return {
    type: STORE_STOREFRONTID,
    payload: {
      id,
    },
  };
}

export function getStorefrontData(store: any) {
  return store.storefrontData?.data;
}

const initialState = {
  pending: false,
  data: {},
  error: null,
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_STOREFRONT_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      };

    case FETCH_STOREFRONT_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      };

    case FETCH_STOREFRONT_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      };

    case STORE_STOREFRONTID:
      return {
        ...state,
        data: {
          ...state.data,
          storefrontId: action.payload.id,
        },
      };

    default:
      return state;
  }
}
