export const getBrandName = (): string => {
  return process.env.REACT_APP_WEBSITE_BRAND === 'vw5' || !process.env.REACT_APP_WEBSITE_BRAND ? 'vw' : process.env.REACT_APP_WEBSITE_BRAND;
};

export const CONNECTION_TIMEOUT = 30000;

export enum TransactionStatus {
  INITIALIZED = 'INITIALIZED',
  APPLICATIONFORM_COMPLETED = 'APPLICATIONFORM_COMPLETED',
  APPLICATION_DATA_SAVED = 'APPLICATION_DATA_SAVED',
  DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',
  LEGITIMATION_CONDUCTED = 'LEGITIMATION_CONDUCTED',
  LEGITIMATION_REFUSED = 'LEGITIMATION_REFUSED',
  UNKNOWN = 'UNKNOWN',
  CREDIT_PENDING = 'CREDIT_PENDING',
  CREDIT_APPROVED = 'CREDIT_APPROVED',
  CREDIT_REFUSED = 'CREDIT_REFUSED',
  APPLICATIONFORM_STARTED = 'APPLICATIONFORM_STARTED',
  CONTRACT_CONFIRMED = 'CONTRACT_CONFIRMED',
  COMPLETED = 'APPLICATIONFORM_COMPLETED',
  TIMER_EXPIRED = 'TIMER_EXPIRED',
  BOP_COMPLETED = 'BOP_COMPLETED',
  ESIGN_READY = 'ESIGN_READY',
  ESIGN_COMPLETED = 'ESIGN_COMPLETED',
  ESIGN_REFUSED = 'ESIGN_REFUSED',
}

export const DocumentsMaxFiles = {
  salaryProof: 9,
  addressProof: 9,
  ibanProof: 9,
};

export const DocumentsMinFiles = {
  salaryProof: 2,
  addressProof: 1,
  ibanProof: 1,
};

export const MAX_FILESIZE = 1000 * 1000 * 4.5;
export const ANALYTICS_DEFAULTS = {
  core: {
    dataLayerVersion: '2.3',
    pageInfo: {
      pageName: null,
      intendedCustomerDeviceType: 'all',
      version: 'R1',
      releaseDate: '2020-05-25',
      language: 'xx',
      market: 'XX',
      publisher: 'DU',
    },
    category: {
      primaryCategory: 'financing',

      siteType: 'standalone',
    },
    attributes: {
      journeyType: 'customer-facing-journey',
      viewChange: null,
      brand: getBrandName(),
    },
  },
  error: {
    errorCode: null,
    errorMessage: null,
    errorCausingURL: null,
  },
  product: [
    {
      name: 'AutoCredit',
      category: 'Finance',
    },
  ],
  event: [
    {
      eventInfo: {
        eventType: null,
        eventAction: null,
        eventTargetURL: null,
      },
    },
  ],
};

const addLeadingZero = (number: number) => (number <= 9 ? `0${number}` : number);

export const transformDate = (date: Date) => {
  if (!date) return undefined;
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth() + 1;
  const year = new Date(date).getFullYear();
  const hour = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();

  const correctDay = addLeadingZero(day);
  const correctMonth = addLeadingZero(month);

  return `${correctDay}.${correctMonth}.${year} ${hour}:${minutes}`;
};

export const getValidityDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  return transformDate(date);
};
