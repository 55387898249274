export const SET_DATA_MODELS = 'dataModels//SET_DATA_MODELS';

export interface Store {
  dataModels: {
    data: {
      countryCodes?: {
        value: string;
        label: string;
      }[];
    };
  };
}

export interface State {
  data: {
    countryCodes?: {
      value: string;
      label: string;
    }[];
  };
}

export interface ModelsData {
  countryCodes?: {
    value: string;
    label: string;
  }[];
}

interface SaveModelsData {
  type: typeof SET_DATA_MODELS;
  payload: ModelsData;
}

export type ModelActionTypes = SaveModelsData;

export function setDataModels(data: ModelsData): ModelActionTypes {
  return {
    type: SET_DATA_MODELS,
    payload: data,
  };
}

export function getDataModels(store: Store): ModelsData {
  return store.dataModels && store.dataModels.data;
}

const initialState = {
  data: {},
};

export default function(state = initialState, action: ModelActionTypes): State {
  switch (action.type) {
    case SET_DATA_MODELS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}
