export const SET_UPLOADED_DOCUMENT = 'documents//SET_UPLOADED_DOCUMENT';
export const SET_ALL_UPLOADED_DOCUMENTS = 'documents//SET_ALL_UPLOADED_DOCUMENTS';
export const DELETE_DOCUMENT = 'documents//DELETE_DOCUMENT';

export enum FileType {
  AddressProof = 'addressProof',
  SalaryProof = 'salaryProof',
  IbanProof = 'ibanProof',
}

export enum FileUploadStatus {
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
  UNSUPPORTED = 'UNSUPPORTED',
  TOO_BIG = 'TOO_BIG',
  ERROR = 'ERROR',
}

export interface FileValue {
  bytes?: string;
  error?: string;
  state?: FileUploadStatus;
  id: string;
  name: string;
  fileSize: number;
  mimeType: string;
  tags: FileType[];
}

export interface Store {
  documents: {
    [FileType.AddressProof]: FileValue[];
    [FileType.IbanProof]: FileValue[];
    [FileType.SalaryProof]: FileValue[];
  };
}

interface SetDocumentAction {
  type: typeof SET_UPLOADED_DOCUMENT;
  payload: FileValue;
}

interface SetAllDocumentsAction {
  type: typeof SET_ALL_UPLOADED_DOCUMENTS;
  payload: FileValue[];
}

interface DeleteDocumentAction {
  type: typeof DELETE_DOCUMENT;
  payload: {
    id: FileValue['id'];
    tags: FileValue['tags'];
  };
}

type DocumentActionTypes = SetDocumentAction | SetAllDocumentsAction | DeleteDocumentAction;

export const setAllUploadedDocuments = (documentsData: SetAllDocumentsAction['payload']): SetAllDocumentsAction => {
  return {
    type: SET_ALL_UPLOADED_DOCUMENTS,
    payload: documentsData,
  };
};

export const setUploadedDocument = (documentData: SetDocumentAction['payload']): SetDocumentAction => {
  return {
    type: SET_UPLOADED_DOCUMENT,
    payload: documentData,
  };
};

export const getAllUploadedDocuments = (store: any) => {
  return store.documents.documents;
};

export const deleteDocument = (documentData: DeleteDocumentAction['payload']): DeleteDocumentAction => {
  return {
    type: DELETE_DOCUMENT,
    payload: documentData,
  };
};

const parseAllDocumentsToRedux = (documents: FileValue[]): Store['documents'] => {
  return documents.reduce((res, file) => {
    const fileType = file.tags[0];

    if (fileType) {
      if (!res[fileType]) {
        res[fileType] = [];
      }

      res[fileType].push({ ...file, state: FileUploadStatus.SUCCESS });
    }

    return res;
  }, {} as any);
};

const initialState = {
  documents: {
    [FileType.AddressProof]: [],
    [FileType.IbanProof]: [],
    [FileType.SalaryProof]: [],
  },
};

export default function(state = initialState, action: DocumentActionTypes): Store {
  switch (action.type) {
    case SET_UPLOADED_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.payload.tags[0]]: [...(state.documents[action.payload.tags[0]] ?? []), action.payload],
        },
      };
    case SET_ALL_UPLOADED_DOCUMENTS:
      return {
        ...state,
        documents: parseAllDocumentsToRedux(action.payload),
      };

    case DELETE_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.payload.tags[0]]: state.documents[action.payload.tags[0]].filter((doc: FileValue) => doc.id !== action.payload.id),
        },
      };
    default:
      return state;
  }
}
