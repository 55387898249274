import { getBrandName } from '../../config';

/**
 * The base resource needs to be declared in this file and not imported,
 * because it must resolve to a string directly, otheriwse it will not work in
 * the getPages and getModels methods.
 */
const BASE_RESOURCE = 'base';

export interface LogoSrc {
  logoDefault: string;
  logoSmall: string;
}

export interface Page {
  name: string;
  url: string;
  file: string;
  target: string;
}

export const getBronsonBrand = (brand: string): string => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel';
    case 'vw':
    case 'vwcv':
    case 'vwn':
      return 'vw6';
    default:
      return brand;
  }
};

export const productName = (): string => {
  const product = getBrandName();
  switch (product) {
    case 'vw':
    case 'vwfs':
    case 'vw6':
    case 'vwcv':
      return 'Volkswagen';
    case 'audi':
      return 'Audi';
    default:
      return product;
  }
};

export const getPages = (brand: string) => {
  return import(`../../resources/${brand}/pages/index.json`).catch(() => {
    return import(`../../resources/${BASE_RESOURCE}/pages/index.json`);
  });
};

export const getModels = (brand: string) => {
  return import(`../../resources/${brand}/models`).catch(() => {
    return import(`../../resources/${BASE_RESOURCE}/models`);
  });
};

export const getStyles = (brand = 'vw6') => {
  return import(`@vwfs-bronson/bronson-${getBronsonBrand(brand)}/dist/css/style.min.css`).then(() => import('../../styles.scss'));
};

export const getLogosSrc = async (brandName: string) => {
  let logoDefault;
  let logoSmall;

  switch (brandName) {
    case 'skoda':
    case 'vw6':
    case 'seat':
    case 'audi':
      logoDefault = await import(`@vwfs-bronson/bronson-${brandName}/dist/img/logo.svg`);

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoDefault.default,
      };
    case 'vwfs':
      logoDefault = await import(`@vwfs-bronson/bronson-${getBronsonBrand(brandName)}/dist/img/logo.svg`);
      logoSmall = await import(`@vwfs-bronson/bronson-${getBronsonBrand(brandName)}/dist/img/logo-small-screen.svg`);

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
    case 'vw':
    case 'vwcv':
    default:
      logoDefault = await import(`@vwfs-bronson/bronson-${getBronsonBrand(brandName)}/dist/img/logo.svg`);

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoDefault.default,
      };
  }
};
