export enum ApplicationRoute {
  landing = '/',
  details = '/details',
  summary = '/summary',
  completion = '/completion',
  upload = '/upload',

  legitimation = '/legitimation',
  legitimationRedirect = '/legitimation-redirect',
  identificationTimeout = '/identification-timeout',
  legitimationRefused = '/legitimation-refused',

  dealerEsignStart = '/dealer-esign-start',
  customerEsignStart = '/esign-start',
  esignSuccess = '/esign-success',
  esignRefused = '/esign-refused',

  creditApproved = '/credit-approved',
  creditRefused = '/credit-refused',
  creditPending = '/credit-pending',

  authenticate = '/authenticate',
  linkExpired = '/link-expired',
  error = '/error',
}

export const notProtectedRoutes = new Set([
  ApplicationRoute.legitimationRedirect,
  ApplicationRoute.customerEsignStart,
  ApplicationRoute.dealerEsignStart,
  ApplicationRoute.esignRefused,
  ApplicationRoute.esignSuccess,
  ApplicationRoute.completion,
  ApplicationRoute.linkExpired,
]);
