import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Layout, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import * as history from 'history';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationRoute as routes } from '../../routes';

const NavigationBlockedModal: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const show = () => {
    setVisible(true);
  };

  const registerBlockingNavigation = (history: history.History) => {
    let currentPathname = '';
    let currentSearch = '';

    history.listen((newLocation, action) => {
      if (action === 'PUSH') {
        if (newLocation.pathname !== currentPathname || newLocation.search !== currentSearch) {
          // Save new location
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
            state: newLocation.state,
          });
        }
      } else {
        if (history.location.pathname === routes.error) {
          // Skip blocking on error page
          return;
        }

        // Send user back if they try to navigate back
        history.go(1);
        show();
      }
    });
  };
  useEffect(() => {
    registerBlockingNavigation(history);
  }, []);

  const hide = () => {
    console.log('close');
    setVisible(false);
  };

  const isVisible = visible && history.location.pathname !== routes.error;

  return (
    <Modal
      testId='closingAlertModal'
      id='closingAlertModal'
      title={t('navigationBlockedModal:title')}
      shown={isVisible}
      onClose={hide}
      onClickOutside={hide}
    >
      <Layout>
        <Layout.Item>
          <Paragraph className='u-text-left'>{t('navigationBlockedModal:content')}</Paragraph>
        </Layout.Item>
        <Layout.Item>
          <Button type='button' onClick={hide}>
            {t('navigationBlockedModal:close')}
          </Button>
        </Layout.Item>
      </Layout>
    </Modal>
  );
};

export default NavigationBlockedModal;
