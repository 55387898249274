import { createStore, combineReducers } from 'redux';
import formReducer from './features/form.redux';
import dataModelsReducer from './features/data-models.redux';
import storefrontReducer from './features/storefront.redux';
import authReducer from './features/auth.redux';
import customReducer from './features/custom.redux';
import documentsReducer from './features/documents.redux';

const store = createStore(
  combineReducers({
    form: formReducer,
    dataModels: dataModelsReducer,
    storefrontData: storefrontReducer,
    auth: authReducer,
    custom: customReducer,
    documents: documentsReducer,
  }),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

/* eslint-enable */

export { store };
