export const RESET_TOKEN_DATA = 'auth//RESET_TOKEN_DATA';
export const SAVE_TOKEN_DATA = 'auth//SAVE_TOKEN_DATA';
export const RESET_ID_DATA = 'auth//RESET_ID_DATA';
export const SAVE_ID_DATA = 'auth//SAVE_ID_DATA';

export interface Store {
  auth: {
    token: string;
    id: string;
  };
}

export interface State {
  token: string;
  id: string;
}

interface SaveTokenData {
  type: typeof SAVE_TOKEN_DATA;
  payload: string;
}

interface ResetTokenData {
  type: typeof RESET_TOKEN_DATA;
}

interface SaveIdData {
  type: typeof SAVE_ID_DATA;
  payload: string;
}

interface ResetIdData {
  type: typeof RESET_ID_DATA;
}

export type DataActionTypes = SaveTokenData | ResetTokenData | SaveIdData | ResetIdData;

export function saveTokenData(data: string): DataActionTypes {
  return {
    type: SAVE_TOKEN_DATA,
    payload: data,
  };
}

export function resetTokenData(): DataActionTypes {
  return {
    type: RESET_TOKEN_DATA,
  };
}

export function getTokenData(store: Store): string | undefined {
  return store.auth.token;
}

export function saveIdData(data: string): DataActionTypes {
  return {
    type: SAVE_ID_DATA,
    payload: data,
  };
}

export function resetIdData(): DataActionTypes {
  return {
    type: RESET_ID_DATA,
  };
}

export function getIdData(store: Store): string | undefined {
  return store.auth.id;
}

const initialState = {
  token: '',
  id: '',
};

export default function(state = initialState, action: DataActionTypes): State {
  switch (action.type) {
    case RESET_TOKEN_DATA:
      return {
        ...state,
        token: '',
      };

    case SAVE_TOKEN_DATA:
      return {
        ...state,
        token: action.payload,
      };
    case RESET_ID_DATA:
      return {
        ...state,
        id: '',
      };

    case SAVE_ID_DATA:
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
}
