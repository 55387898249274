export const SET_PAGE_NAME = 'dataModels//SET_PAGE_NAME';
export const SET_ERROR_STATUS = 'dataModels//SET_ERROR_STATUS';
export const SET_QUERY_URL = 'dataModels//SET_QUERY_URL';
export interface ErrorData {
  errorType: string;
  errorMessage: string;
}
export interface Store {
  custom: {
    data: {
      pageName: string;
      queryUrl: string;
      error: ErrorData;
    };
  };
}

export interface State {
  data: {
    pageName?: string;
    queryUrl: string;
    error: ErrorData;
  };
}
interface SetPageName {
  type: typeof SET_PAGE_NAME;
  payload: string;
}
interface SetQueryUrl {
  type: typeof SET_QUERY_URL;
  payload: string;
}
interface setErrorStatus {
  type: typeof SET_ERROR_STATUS;
  payload: ErrorData;
}
export type QueryUrlTypes = SetQueryUrl;
export type PageNameTypes = SetPageName;
export type ErrorTypeTypes = setErrorStatus;
export function setQueryUrl(data: string): QueryUrlTypes {
  return {
    type: SET_QUERY_URL,
    payload: data,
  };
}
export function setPageName(data: string): PageNameTypes {
  return {
    type: SET_PAGE_NAME,
    payload: data,
  };
}
export function setErrorStatus(data: ErrorData): ErrorTypeTypes {
  return {
    type: SET_ERROR_STATUS,
    payload: data,
  };
}
export function getQueryUrl(store: Store): string {
  return store.custom && store.custom.data.queryUrl;
}
export function getPageName(store: Store): string {
  return store.custom && store.custom.data.pageName;
}
export function getErrorStatus(store: Store): ErrorData {
  return store.custom && store.custom.data.error;
}
const initialState = {
  data: {
    pageName: '',
    error: {
      errorType: '',
      errorMessage: '',
    },
    queryUrl: '',
  },
};

export default function(state = initialState, action: any): State {
  switch (action.type) {
    case SET_PAGE_NAME:
      return {
        ...state,
        data: { ...state.data, pageName: action.payload },
      };
    case SET_ERROR_STATUS:
      return {
        ...state,
        data: { ...state.data, error: action.payload },
      };
    case SET_QUERY_URL:
      return {
        ...state,
        data: { ...state.data, queryUrl: action.payload },
      };
    default:
      return state;
  }
}
