import { PersonalDetails, ContactDetails, EmploymentDetails, PaymentDetails, DocumentsUploaded } from '../../../types';
import { Summary } from '../../../types/Summary';

export const RESET_FORM_DATA = 'form//RESET_FORM_DATA';
export const SAVE_FORM_DATA = 'form//SAVE_FORM_DATA';
export const SET_STEP_DATA = 'form//SET_STEP_DATA';
export const SET_FORM_DATA = 'form//SET_FORM_DATA';

export interface Store {
  form: {
    stepData: {
      personalDetails?: PersonalDetails;
      contactDetails?: ContactDetails;
      employmentDetails?: EmploymentDetails;
      paymentDetails?: PaymentDetails;
      documentsUploaded?: DocumentsUploaded;
      summary?: Summary;
    };
  };
}

export interface State {
  stepData: {
    personalDetails?: PersonalDetails;
    contactDetails?: ContactDetails;
    employmentDetails?: EmploymentDetails;
    paymentDetails?: PaymentDetails;
    documentsUploaded?: DocumentsUploaded;
    summary?: Summary;
  };
}

export interface Data {
  stepData: {
    personalDetails?: PersonalDetails;
    contactDetails?: ContactDetails;
    employmentDetails?: EmploymentDetails;
    paymentDetails?: PaymentDetails;
    documentsUploaded?: DocumentsUploaded;
    summary?: Summary;
  };
}
export enum StepDetails {
  PERSONAL_DETAILS = 'personalDetails',
  CONTACT_DETAILS = 'contactDetails',
  EMPLOYMENT_DETAILS = 'employmentDetails',
  PAYMENT_DETAILS = 'paymentDetails',
  DOCUMENTS_UPLOADED = 'documentsUploaded',
  SUMMARY = 'summary',
}

interface SetFormData {
  type: typeof SET_FORM_DATA;
  payload: {
    step: StepDetails;
    data: any;
  };
}

interface ResetFormData {
  type: typeof RESET_FORM_DATA;
}

interface SetStepData {
  type: typeof SET_STEP_DATA;
  payload: {
    step: StepDetails;
    data: PersonalDetails | ContactDetails | EmploymentDetails | PaymentDetails | DocumentsUploaded | Summary;
  };
}

export type DataActionTypes = SetFormData | ResetFormData | SetStepData;

export function resetFormData(): DataActionTypes {
  return {
    type: RESET_FORM_DATA,
  };
}

export function getFormData(store: Store): Data | undefined {
  return store.form;
}

export function setStepData(
  step: StepDetails,
  data: PersonalDetails | ContactDetails | EmploymentDetails | PaymentDetails | DocumentsUploaded | Summary
): DataActionTypes {
  return {
    type: SET_STEP_DATA,
    payload: {
      step,
      data,
    },
  };
}

export function getStepData(
  store: Store,
  step: StepDetails
): PersonalDetails | ContactDetails | EmploymentDetails | PaymentDetails | DocumentsUploaded | Summary | undefined {
  return store.form.stepData && store.form.stepData[step];
}

export function setFormData(data: any) {
  return {
    type: SET_FORM_DATA,
    payload: data,
  };
}

const initialState = {
  stepData: {},
};

export default function(state = initialState, action: DataActionTypes): State {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...initialState,
        stepData: {
          personalDetails: action.payload.data.personalDetails,
          contactDetails: action.payload.data.contactDetails,
          employmentDetails: action.payload.data.employmentDetails,
          paymentDetails: action.payload.data.paymentDetails,
          documentsUploaded: action.payload.data.documentsUploaded,
        },
      };

    case RESET_FORM_DATA:
      return {
        ...initialState,
      };

    case SET_STEP_DATA:
      return {
        ...state,
        stepData: {
          ...state.stepData,
          [action.payload.step]: action.payload.data,
        },
      };

    default:
      return state;
  }
}
